import React, { useState, useEffect } from "react";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { useMedia } from "use-media";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_2.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });

  return (
    <>
      <TitleAndMetaTags
        title="Will your workplace support perish or thrive in post covid-19 world?"
        description="Learn how companies are transforming their traditional support to modern workplace support using workplace automation tools."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Will your workplace support perish or survive
                            (better yet thrive)?
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          Today, users are very savvy. With a tap of a button,
                          people can rent, buy, lease, sell, talk, chat and
                          basically can do everything one needs in day to day
                          aspect of life. From a business perspective, companies
                          are finding new ways to engage users to do business.
                          The new startups have advantage in that they start
                          fresh, but we have also seen companies pushing digital
                          transformation to either adopt or perish to be
                          meaningful in today’s demanding environment. But we
                          also know that it’s very difficult for companies to
                          adopt and transform their business. Industry is
                          littered with more failures than success and also a
                          heavy cost to pay. One thing that most companies fail
                          is to understand is that transformation is not just
                          about the front office. It’s also very much about the
                          internals – the back office. I would argue that in the
                          new world, there is no front vs back office. You are
                          either digital or in the process of being digital.
                          Remember that today users are savvy, so your employees
                          are your excellent source of both inspiration and also
                          your testing grounds. In any case, employees today
                          expect a similar level of digital touch-points and
                          channels to do their work. The line between B2C and
                          B2B is fading as more and more users are exposed to
                          consumer services delivered via ever evolving
                          technology. Whilst companies feel the pressure, they
                          can also use this as a leverage, an inspiration to act
                          and transform.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          Whilst, some companies (B2B) have understood the
                          opportunity and have started transforming their legacy
                          systems to better provide workplace tools, they still
                          fall short in providing the workplace support
                          experience or employee support experience no matter
                          new tools.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-process-automation">
                            Workplace support is an integral part of employee
                            support
                          </a>{" "}
                          in day to day work for e.g. when a laptop break. When
                          an employee requests a leave, or a new request to
                          access a new application due to job role change, and
                          many more. Today, most of the companies, big and small
                          rely on the traditional model of email and phone (IT
                          Helpdesk) to provide support. Which have worked in
                          traditional (arguably) monolithic age. But these fall
                          short in the modern times, when you have savvy user
                          who probably uses “Alexa” or “Siri” to order food or
                          return a purchase.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <p class="font-section-normal-text line-height-2">
                          In this blog, we will only focus on the elephant in
                          the room (psst… the IT Helpdesk)
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          One of the first thing to focus on in transformation,
                          is making sure companies properly understand the
                          current environment (or problem statement). And there
                          are many ways to do that. Easiest and generally an
                          effective way is to start with listing down the key
                          areas of to focus. Currently IT Helpdesk is a
                          strategic and tactical way for companies to provide
                          workplace support. Let’s list down the issues
                          associated with it.
                        </p>

                        <h2 className="font-section-sub-header-small-bold">
                          Cost
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          On an average and an example, a med size company
                          spends about 12-14$ per support ticket. Some of big
                          items in that costs includes IT support application,
                          connectivity, call management software, Helpdesk
                          agents (Human Labor), office space etc. the biggest
                          cost by far is the IT Helpdesk agents and office space
                          facilities. so, if you are a company with 500
                          employees and assuming you get two tickets per month
                          per employee, your cost can go up to 12000$ per month
                          alone. That’s a lot on what we could argue as “sunk”
                          cost as it is generating revenue.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Agent Productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Today, if employees had issues, they call or email IT
                          Helpdesk to get resolutions which is recorded as a
                          support ticket in an ITSM tool (IT Helpdesk ticketing
                          tool). Some ITSM tools allow for integration with
                          email tools so tickets are created automatically. But
                          most ITSM don’t. regardless, a vast majority of time
                          spent by agents is creating, updating and following up
                          on IT Helpdesk ticket which involves a lot of time
                          spent on making sure tickets are up to date. Also
                          finding information to provide resolutions is
                          cumbersome because knowledge base is not updated and
                          worst yet, needs constant update in way the
                          information itself is available at the right moment.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Data
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          companies are beginning to acknowledge that data is
                          the most valuable asset (only after employees) and big
                          data can truly help tell (predict) a story that was
                          not possible before. Same applies for support. The
                          issue with tickets and email is a lot of soft
                          (experience) and hard data (problem) are locked in the
                          system. ITSM do provide analytics tool, but it
                          restricts only the hard ticket data, and does not
                          account for other sources such as employee emotions,
                          experience,{" "}
                          <a href="https://workativ.com/conversational-ai-platform/traditional-support">
                            impact on support resolution.
                          </a>
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Employee Productivity
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Companies spend thousands of dollars on training
                          employees, giving them tools to ensure they are
                          successful. But having stuck on a support call for 300
                          mins and back and forth on email can drain the
                          productivity. The loss is not just productivity and
                          time, it’s also the emotional and psychological aspect
                          of rebound. Imagine you have a time bound deliverable
                          and your account is locked due to some reason. It
                          definitely impacts or halts your productivity.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Reactive Approach
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Most workplace support waits for issues to be
                          reported. This causes the IT Helpdesk and in general
                          IT team to be reactive in fixing issues. The problem
                          with this approach is basically the cost and resources
                          spent are assuming on skills, amount of resources
                          required etc. The reactive nature of providing support
                          creates a “tech” or “support” debt. i.e. the staff are
                          always chasing to clear the support tickets.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Traditional Support Model
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          The idea of providing support via email and phone call
                          is bit archaic to our savvier employee who probably
                          uses WhatsApp and Uber to get things done. There are
                          multiple issues here with having a{" "}
                          <a href="https://workativ.com/conversational-ai-platform/traditional-support">
                            “traditional” support.
                          </a>{" "}
                          First, it assumes employees do not work out of normal
                          office hours and assumes employees work out of single
                          location which can cause severe inconvenience to
                          employees working in shifts, traveling and or work
                          from home. Even if companies were to add, new channel,
                          let’s say, chat, companies are only adding more costs,
                          tools, and of-course, now more agents. More like a
                          band aid fix.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Human Resource / FTE Driven
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          When employees reach out for support, whether via
                          email, phone or chat, it is still the IT Helpdesk
                          agent who is responsible, and engaged from the moment
                          they have picked up that call or email. So, you can
                          imagine, simply adding a new channel like chat could
                          potentially only increase the agent resources required
                          and essentially the cots. Not the mention, the high
                          attrition, agent training and productivity issues. The
                          problem is that the current model heavily relies on
                          human resources to deliver the support.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Analytics
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Continuing on the “data’ issue discussed earlier,
                          companies who are stuck with data silos lack
                          efficiency, innovation and mainly the deeper insight
                          required to progress. How do we improve something that
                          we cannot track. Companies typically use single source
                          of data i.e. tickets to analyse support. But what
                          about the quality of service? How do we track patterns
                          that could help us be more proactive? Surely analytics
                          plays a huge part to give us the vision to improve but
                          unified data is a challenge.
                        </p>
                        <h6 className="font-section-sub-header-small-bold">
                          Manual Process
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Companies today have several internal applications.
                          Most of these are off-the-shelf with lot of
                          customisations to suit company process and operations.
                          When employees raise a ticket with Helpdesk, agents
                          typically have to follow a process to resolve an issue
                          and this will mean access to different applications.
                          As an example, adding a user to a security email
                          distribution list, may involve the agent or an IT
                          staff to access email application. This involves
                          manual working on the application to be able to
                          support resolution.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul className="section__ul">
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/ms-teams-bot-workplace-support-bot">
                                MS Teams Bot - Your ultimate workplace support
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/5-help-desk-tasks-everyone-should-automate">
                                {" "}
                                5 Help Desk Tasks Everyone Should Automate
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/virtual-agent-powered-it-helpdesk">
                                How Virtual Agent can help IT Service Desks?
                                Upgrade to a Virtual Agent powered IT Helpdesk
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}

            {/* <GoToPopup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
